import {customElement, property} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {TemplateModel} from "../../../common/template";
import {LocatedDates} from "../../../common/utils/dates/locatedDates";
import {resolve} from "../../../container";
import {CONFIRMABLE_CHANNELS, ICONS, TAG_LIMIT} from "../feedCommons";
import Styles from "../../../common/feedTile.lit.scss";
import type {FeedEntry, FeedEntryData} from "../feedEntry";
import {ifDefined} from "lit/directives/if-defined.js";

enum ContentTypeClass {
    PAGE = "page",
    SOCIAL = "social-media",
    YOUTUBE = "youtube",
    PRESS = "press"
}

export type FeedTileConfig = {
    arrangement: string;
    informationDensityClass: string;
    cardBoxingClass: string;
    usePageOverlay: boolean;
    contextPreservingPageOverlay: boolean;
    topicsLinked: boolean;
}

@customElement("eop-feed-tile")
export class EopFeedTile extends LitElement {

    public static readonly styles = Styles;

    @property()
    public entry: FeedEntry;
    @property()
    public config: FeedTileConfig;

    private data: FeedEntryData;

    public constructor(
        private dates: LocatedDates = resolve(LocatedDates)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.data = this.entry.data;
    }

    public render(): TemplateResult {
        return this.needsConfirmation()
            ? this.renderConfirmableTile()
            : this.renderTileWithoutConfirmation();
    }

    private needsConfirmation(): boolean {
        return !!this.data.contentType && CONFIRMABLE_CHANNELS.includes(this.data.contentType);
    }

    private renderConfirmableTile(): TemplateResult {
        return html`
            <eop-load-after-confirmation
                    module-name="youtube"
                    confirmation-text-key="YOUTUBE_CONFIRMATION_TEXT"
                    button-label-key="YOUTUBE_CONFIRMATION_BUTTON_LABEL"
            >
                <span slot="content-to-confirm">
                    ${this.renderTileWithoutConfirmation()}
                </span>
            </eop-load-after-confirmation>
        `;
    }

    private renderTileWithoutConfirmation(): TemplateResult {
        return this.config.cardBoxingClass === "card-style-image-teaser"
            ? this.renderImageTeaserTile()
            : this.renderNormalTile();
    }

    private renderNormalTile(forImageTeaser: boolean = false): TemplateResult {
        if (this.config.usePageOverlay) {
            return html`
                <eop-page-overlay-link
                        href=${this.data.url}
                        data-tracking-label=${this.data.headline ?? "tile"}
                        context-preserving=${ifDefined(this.config.contextPreservingPageOverlay ? "true" : undefined)}
                        class="tile ${this.contentTypeClass()} ${this.config.informationDensityClass} ${this.config.cardBoxingClass} ${this.config.arrangement} ${forImageTeaser ? "for-image-teaser" : ""}"
                >
                    ${this.renderInnerTile()}
                </eop-page-overlay-link>
            `;
        }
        return html`
            <a href=${this.data.url}
               data-tracking-label=${this.data.headline ?? "tile"}
               target=${ifDefined(this.entry.isExternalLink() ? "_blank" : undefined)}
               class="tile ${this.contentTypeClass()} ${this.config.informationDensityClass} ${this.config.cardBoxingClass} ${this.config.arrangement} ${forImageTeaser ? "for-image-teaser" : ""}"
            >
                ${this.renderInnerTile()}
            </a>
        `;
    }

    private renderInnerTile(): TemplateResult {
        return html`
            <div class="tile-image">
                ${this.imageElement()}
            </div>
            <div class="tile-text">
                <div class="tile-top">
                    <div class="metadata">${this.metaData()}</div>
                </div>
                ${this.renderHeadline()}
                <div class="tile-description">
                    <span class="description-text">${this.data.subHeadline ? unsafeHTML(this.data.subHeadline) : ""}</span>
                    ${this.renderTopics()}
                </div>
                ${this.iconElement()}
            </div>
        `;
    }

    private renderImageTeaserTile(): TemplateResult {
        return html`
            ${this.config.arrangement === "mobile-condensed" ? this.renderNormalTile(true) : ""}
            <eop-image-teaser
                    link=${this.data.url}
                    headline=${this.data.headline}
                    target=${ifDefined(this.entry.isExternalLink() ? "_blank" : undefined)}
                    hover-text=${this.data.imageAltText}
                    image-background="dark-image"
                    image-src=${this.entry.getImageUrlFor4x3Purpose()}
                    image-shade="image-shade-strong"
                    image-size="image-teaser-medium"
                    ?open-in-page-overlay=${this.config.usePageOverlay}
                    class=${this.config.arrangement}
                    .tags=${this.config.informationDensityClass !== "minimal" ? this.renderTopics() : ""}>
            </eop-image-teaser>
        `;
    }

    private renderHeadline(): TemplateResult | null {
        return this.data.headline
            ? html`
                    <div class="tile-headline">${unsafeHTML(this.data.headline)}</div>`
            : null;
    }

    private renderTopics(): TemplateResult {
        return html`
            <div class="tags">${(this.renderTopicTags())}
            </div>
        `;
    }

    private renderTopicTags(): TemplateResult[] {
        return this.data.topics
            .filter(it => this.config.topicsLinked ? !!it.url : it)
            .slice(0, TAG_LIMIT)
            .map(topic => html`
                <eop-feed-topic-tag ?linked=${this.config.topicsLinked} .topic=${topic}></eop-feed-topic-tag>`);
    }

    private imageElement(): TemplateResult {
        if (this.data.mediaType === "image") {
            return html`
                <eop-plain-image
                        src=${this.entry.getImageUrlFor7x4Purpose()}
                        alt=${this.data.imageAltText}
                        class="eop-image"
                ></eop-plain-image>
            `;
        } else if (this.data.contentType === "youtube") {
            return html`
                <eop-confirmable-iframe-element
                        src=${this.entry.getImageUrlFor7x4Purpose()}
                        module-name="youtube"
                        frame-title=${this.data.headline}
                ></eop-confirmable-iframe-element>`;
        } else {
            return html`
                <eop-responsive-image image-src=${this.entry.getImageUrlFor7x4Purpose()} image-alt=${this.data.imageAltText}></eop-responsive-image>`;
        }
    }

    private iconElement(): Element | Text | undefined {
        const icon = this.data.contentType ? ICONS[this.data.contentType] : null;
        if (!icon) {
            return document.createTextNode("");
        }
        return new TemplateModel(icon)
            .addClasses("feed-type-icon")
            .asElement();
    }

    private metaData(): string {
        const date = this.dates.toLocalDateString(new Date(this.data.publishedDate));
        if (this.data.principalKeyword) {
            return `${date} | ${this.data.principalKeyword}`;
        }
        return `${date}`;
    }

    private contentTypeClass(): ContentTypeClass {
        switch (this.data.contentType) {
            case "twitter":
            case "facebook":
            case "instagram":
            case "linkedin":
                return ContentTypeClass.SOCIAL;
            case "youtube":
                return ContentTypeClass.YOUTUBE;
            case "press":
                return ContentTypeClass.PRESS;
            default:
                return ContentTypeClass.PAGE;
        }
    }
}