import type {PropertyMap} from "../../../common/utils/objects";
import {SearchFacade} from "./searchFacade";
import {resolve} from "../../../container";
import {customElement, property} from "lit/decorators.js";
import {ScrollService} from "../../../common/scroll";
import {QueryParameters} from "../../../common/queryParameters";
import {Deferred} from "../../../common/utils/promises";
import {UnLitElement} from "../../../common/elements";
import type {FeedChannel} from "../feedCommons";
import {DefaultSearchGroup, type SearchGroup} from "./searchGroup";
import {TAB_QUERY_PARAMETER_NAME} from "./constants";

@customElement("eop-search-group-tab")
export class SearchGroupTab extends UnLitElement {

    @property({attribute: "sources", type: Object})
    private sources: PropertyMap<FeedChannel>;
    @property({attribute: "name"})
    private name: string;

    private groupRegistered: Deferred<SearchGroup>;

    public constructor(
        private searchFacade: SearchFacade = resolve(SearchFacade),
        private scrollService: ScrollService = resolve(ScrollService),
        private queryParameters: QueryParameters = resolve(QueryParameters)
    ) {
        super();
        this.groupRegistered = new Deferred();
        this.searchFacade.registerGroupInitialization(async (sf) => {
            await this.groupRegistered.promise;
            return sf;
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();
        const group = new DefaultSearchGroup(this.name, this.sources);
        this.registerGroup(group);

        this.addEventListener("click", () => this.toggleActiveGroup(group.name));
    }

    public registerGroup(group: SearchGroup): void {
        this.searchFacade.registerGroup(group);
        group.onActivation((active) => {
            this.classList.toggle("active", active);
            if (active) {
                this.updateGroupName(group.name);
            }
        });
        group.onFocus(() => this.center());
        group.onNewData((entries, newCount) => {
            this.classList.toggle("no-results", entries.length === 0);
            const counterContainerElement = this.querySelector<HTMLElement>(".counter-container");
            const counterElement = counterContainerElement?.querySelector<HTMLElement>(".counter");
            if (counterElement) {
                counterElement.innerText = "" + newCount;
                if (newCount > 0) {
                    counterContainerElement!.classList.add("visible");
                } else {
                    counterContainerElement!.classList.remove("visible");
                }
            }
        });
        if (this.queryParameters.getString(TAB_QUERY_PARAMETER_NAME) === group.name) {
            this.searchFacade.initWith(group.name);
            this.updateGroupName(group.name);
        }
        this.groupRegistered.resolve(group);
    }

    private updateGroupName(name: string): void {
        this.queryParameters.set(TAB_QUERY_PARAMETER_NAME, name);
        this.center(200);
    }

    private center(duration: number = 0): void {
        void this.scrollService.scrollToElementHorizontallyCentered(this, duration);
    }

    public toggleActiveGroup(name: string): void {
        this.searchFacade.switchTo(name);
    }
}