import {customElement, property} from "lit/decorators.js";
import Styles from "./newsSearchPreviewTile.lit.scss";
import {SearchTile} from "../searchTile";
import {html, type TemplateResult} from "lit";
import {ContentSearchImages} from "../contentSearchImages";
import {resolve} from "../../../../container";
import {keyed} from "lit/directives/keyed.js";
import {TileVariant} from "../../feedEntry";
import {TAG_LIMIT} from "../../feedCommons";

@customElement("eop-news-search-preview-tile")
export class EopNewsSearchPreviewTile extends SearchTile {

    @property()
    public resultsPageUrl: string;

    public static readonly styles = Styles;

    public constructor(private images: ContentSearchImages = resolve(ContentSearchImages)) {
        super();
    }

    public render(): TemplateResult {
        return html`${keyed(this.entry.data.url + this.groupName, this.renderTile())}`;
    }

    protected renderImageElement(): TemplateResult {
        const functionalIcons = (!!this.entry.data.lightboxImageUrl && !this.entry.data.disclaimer) ? "lightbox-image-preview-icon" : "";
        const tileDataStyle = this.entry.getTileStyle();
        if (tileDataStyle === TileVariant.DOUBLE) {
            return this.images.doubleImage(this.entry.getImageUrlFor7x4Purpose(), this.entry.getImageUrlFor3x4Purpose(), this.entry.data.imageAltText, functionalIcons);
        } else if (tileDataStyle === TileVariant.LARGE) {
            return this.images.largeImage(this.entry.getImageUrlFor7x4Purpose(), this.entry.getImageUrlFor3x4Purpose(), this.entry.data.imageAltText, functionalIcons);
        }
        return this.images.smallImage(this.entry.getImageUrlFor7x4Purpose(), this.entry.getImageUrlFor4x3Purpose(), this.entry.data.imageAltText, functionalIcons);
    }

    protected renderTopicLinks(): TemplateResult | null {
        if (!this.resultsPageUrl) {
            return null;
        }

        return html`
            <div class=tags>
                ${this.entry.data.topics?.slice(0, TAG_LIMIT)
                        .map(topic => html`
                            <eop-news-tag-link @mouseover=${this.removeHoverClass}
                                               @mouseout=${this.addHoverClass}
                                               .url=${this.resultsPageUrl}
                                               .text=${topic.name}
                                               class="link">
                            </eop-news-tag-link>
                        `)}
            </div>
        `;
    }

    protected showDescription(): boolean {
        return this.entry.getTileStyle() !== TileVariant.SMALL;
    }

    protected headlineText(): string {
        return this.entry.data.headline ?? "---";
    }

    protected subHeadlineText(): string {
        return this.entry.data.subHeadline ?? "";
    }
}